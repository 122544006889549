import React, { useState } from 'react';
import './App.css';

function App() {
  const [text, setText] = useState("Don't let your old console collect dust - turn it into cash with us! We know you love your gadgets, but sometimes it's time to upgrade. Whether it's in pristine condition or in need of a little TLC, we'll give you a fair price for your used console. Plus, we specialize in repairing and refurbishing broken consoles, so you can feel good about giving your old tech a new life. Say goodbye to clutter and hello to cash - sell us your console now!");
  const [buttonText, setButtonText] = useState("Sell Your Console Today");
  
  const handleClick = () => {
    if (text === "New text goes here!") {
      setText("Don't let your old console collect dust - turn it into cash with us! We know you love your gadgets, but sometimes it's time to upgrade. Whether it's in pristine condition or in need of a little TLC, we'll give you a fair price for your used console. Plus, we specialize in repairing and refurbishing broken consoles, so you can feel good about giving your old tech a new life. Say goodbye to clutter and hello to cash - sell us your console now!");
      setButtonText("Sell Your Console Today");
    } else {
      setText("To get a quote, simply send us a text or email with the make and model of your console, as well as any relevant details about its condition. We'll get back to you with a fair price and let you know what the next steps are. It's that easy - don't wait, get in touch with us today!");
      setButtonText("(702) 742-2824 or Beezid3064@gmail.com");
    }
  }

  return (
    <div className="App">
        <div className="header">
          <sapn style={{ color:'#f15b5b'}} >Best</sapn>
          <span style={{ color:'#efbb45'}}>Con</span>
          <span style={{ color: '#000'}}>Sold</span>
        </div>
      <div className="container">
        <p className="bodyText">{text}</p>
        <button onClick={handleClick} className="button">{buttonText}</button>
      </div>
    </div>
  );
}

export default App;
